import React, { useState } from "react";
import PlaybookforContractors from "../../components/_Landing/PlaybookForContractors";
import SEO from "../../components/Seo";

const Services = () => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  return <PlaybookforContractors />;
};

export default Services;

export const Head = () => (
  <SEO
    title={
      "From BIM to Build | Integrated Digital Delivery Services in Singapore"
    }
    description={
      "Tap on our BIM professionals, technology, and workflows for your next project. Deliver better projects while avoiding upfront technology and manpower costs."
    }
  />
);
